import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Icon, Theme, Typography } from '@web-apps/ui-shared'
import {
  useAddContainerWithAffiliateLinkCreationMutation,
  useAddSectionAtomicMutation,
} from '@web-apps/feature-creator-page'
import {
  ExternalLinkMode,
  SectionTypeEnum,
  SectionVariantEnum,
  SectionExtension,
} from '@web-apps/utils-types'

import { ProductFormType } from '../../../MyPage/SectionProductRecommendationsForm/SectionProductionRecommendations.types'

import {
  formatProductFormToApi,
  formatProductRecommendationsToCreationApi,
} from '../../../MyPage/SectionProductRecommendationsForm/SectionProductRecommendationsForm.adapters'

import { AddAffiliateToExistingGrid } from '../AddAffiliateToExistingGrid'
import { useNotification } from './AddToMyPage.hooks'
import {
  StyledAddProductGridContent,
  StyledCrossLine,
} from './AddToMyPage.styles'

type AddToMyPageProps = {
  onSuccess: () => void
  onError: () => void
  product: ProductFormType
  api: {
    createAffiliateLink?: string
    addToGrid: string
    addAsSection: string
  }
}

export const AddToMyPage = ({
  onSuccess,
  onError,
  product,
  api,
}: AddToMyPageProps) => {
  const { t } = useTranslation(['brands'])
  const { onAddProductSuccess, onAddProductError } = useNotification({
    onSuccess,
    onError,
  })

  const containerRef = useRef<HTMLDivElement>(null)

  const [isAddToGridLoading, setIsAddToGridLoading] = useState(false)

  const [
    addContainerWithAffiliateLinkCreation,
    {
      isSuccess: addToNewGridSuccess,
      isError: addToNewGridError,
      isLoading: addToNewGridLoading,
      data: newGridAddedResponse,
    },
  ] = useAddContainerWithAffiliateLinkCreationMutation()

  const [
    addSection,
    {
      isSuccess: addSectionSuccess,
      isError: addSectionError,
      isLoading: addSectionLoading,
      data: sectionAddedResponse,
    },
  ] = useAddSectionAtomicMutation()

  useEffect(() => {
    if (addSectionSuccess && sectionAddedResponse) {
      onAddProductSuccess('single-link', sectionAddedResponse)
    }
  }, [onAddProductSuccess, onSuccess, sectionAddedResponse, addSectionSuccess])

  useEffect(() => {
    if (addSectionError) onAddProductError('single-link')
  }, [addSectionError, onAddProductError, t])

  useEffect(() => {
    if (addToNewGridSuccess && newGridAddedResponse) {
      onAddProductSuccess('new-grid', newGridAddedResponse)
    }
  }, [
    addToNewGridSuccess,
    onSuccess,
    newGridAddedResponse,
    onAddProductSuccess,
  ])

  useEffect(() => {
    if (addToNewGridError) onAddProductError('new-grid')
  }, [addToNewGridError, onAddProductError, t])

  const addNewProductRecommendationsSection = useCallback(() => {
    addContainerWithAffiliateLinkCreation({
      sectionData: formatProductRecommendationsToCreationApi({
        title: t('brands:product_dialog.new_grid_label'),
        published: true,
        styles: {
          variant: SectionVariantEnum.PLAIN,
          is_product_recommendations: true,
          extension: SectionExtension.NONE,
        },
        products: [],
      }),
      path: api.addToGrid,
      item: formatProductFormToApi(product),
      affiliateLinkCreationPath: api.createAffiliateLink || '',
    })
  }, [product, addContainerWithAffiliateLinkCreation, api, t])

  const addNewSection = useCallback(() => {
    addSection({
      path: api.addAsSection,
      sectionData: {
        type: SectionTypeEnum.AFFILIATE_LINK,
        label: product.title,
        href: product.href,
        mode: ExternalLinkMode.LINK,
        image_source:
          product.image?.hasImageSource && product.image.href
            ? product.image.href
            : undefined,
        styles: {
          variant: product.image
            ? SectionVariantEnum.COVER
            : SectionVariantEnum.PLAIN,
        },
        published: true,
      },
      imageData: product.image,
    })
  }, [api.addAsSection, product, addSection])

  const isButtonDisabled =
    addSectionLoading || addToNewGridLoading || isAddToGridLoading

  return (
    <div ref={containerRef}>
      <Flex>
        <StyledAddProductGridContent data-testid="grids-container">
          <div>
            <Button
              width="100%"
              isLoading={isButtonDisabled}
              clickHandler={addNewSection}
            >
              <Flex gap={12} justify="center" align="center">
                <Icon.CustomLink
                  width={20}
                  height={20}
                  fillColor={Theme.Colors.typography.inverse}
                />
                {t('brands:product_dialog.add_single_link_button')}
              </Flex>
            </Button>
          </div>

          <div>
            <Button
              width="100%"
              isLoading={isButtonDisabled}
              clickHandler={addNewProductRecommendationsSection}
            >
              <Flex gap={12} justify="center" align="center">
                <Icon.Grid
                  width={20}
                  height={20}
                  fillColor={Theme.Colors.typography.inverse}
                />
                {t('brands:product_dialog.new_product_grid_button')}
              </Flex>
            </Button>
          </div>
          <StyledCrossLine $overrideColor={Theme.Colors.line}>
            <Typography
              variant="hint"
              as="span"
              color="inactive"
              fontWeight={600}
            >
              {t('brands:product_dialog.separator')}
            </Typography>
          </StyledCrossLine>

          <AddAffiliateToExistingGrid
            api={{ fetchGrids: api.addToGrid }}
            product={product}
            isDisabled={addSectionLoading || addToNewGridLoading}
            onSuccess={(actionVariant, addedSection) => {
              onAddProductSuccess(actionVariant, addedSection)
            }}
            onLoading={(isLoading) => setIsAddToGridLoading(isLoading)}
            onError={onAddProductError}
          />
        </StyledAddProductGridContent>
      </Flex>
    </div>
  )
}
