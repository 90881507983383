import { useFlag } from '@unleash/proxy-client-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useLocation } from 'react-router-dom'

import { Icon } from '@web-apps/ui-shared'

import { ROUTES } from '../../routes/RouteEnums'
import { ItemParentLink, MenuItemType } from './Sidebar.types'

import { FLAG_TRENDING_PRODUCTS_PAGE } from '../../utils/constants/flags.constants'
import { useAccountDetails } from '../../utils/hooks/useAccountDetails.hooks'

type Props = {
  items: MenuItemType[]
  notificationsCount?: number
  myAccountItem: ItemParentLink
  isSidebarHidden: boolean
}

const BRAND_COLLABS: string[] = [
  ROUTES.BRANDS,
  ROUTES.TRENDING_PRODUCTS,
  ROUTES.LINK_CONVERTER,
]

const MY_ACCOUNT_ROUTES: string[] = [ROUTES.PERSONAL_SETTINGS]

const ANALYTICS_ROUTES: string[] = [
  ROUTES.ANALYTICS_EARNINGS,
  ROUTES.ANALYTICS_PAGE,
  ROUTES.ANALYTICS_PAYMENT_DETAILS,
]

const ROUTES_MY_PAGE_NOT_HIDDEN: string[] = [
  ROUTES.MY_PAGE_SETTINGS,
  ROUTES.MY_PAGE_APPEARANCE,
]

export const useIsSidebarMenuHidden = (): boolean => {
  const { pathname } = useLocation()
  const [isSidebarHidden, setIsSidebarHidden] = useState(false)

  useEffect(() => {
    // The rule to hide the menu for all the inner add / edit pages
    setIsSidebarHidden(
      pathname.includes('/my-page/') &&
        !ROUTES_MY_PAGE_NOT_HIDDEN.includes(pathname)
    )
  }, [pathname])

  return isSidebarHidden
}

export const useSidebarMenuItems = (): Props => {
  const { t } = useTranslation(['app'])
  const { metapicUserId } = useAccountDetails()

  const location = useLocation()
  const isSidebarHidden = useIsSidebarMenuHidden()

  const isTrendingProductsActive = useFlag(FLAG_TRENDING_PRODUCTS_PAGE)

  const [isMyAccountActive, setIsMyAccountActive] = useState(false)
  const [isBrandCollabsActive, setIsBrandCollabsActive] = useState(false)
  const [isAnalyticsActive, setIsAnalyticsActive] = useState(false)

  useEffect(() => {
    const pathname = location.pathname
    setIsMyAccountActive(
      Boolean(MY_ACCOUNT_ROUTES.some((substr) => pathname.includes(substr)))
    )
    setIsBrandCollabsActive(
      Boolean(BRAND_COLLABS.some((substr) => pathname.includes(substr)))
    )
    setIsAnalyticsActive(
      Boolean(ANALYTICS_ROUTES.some((substr) => pathname.includes(substr)))
    )
  }, [location.pathname])

  const brandsOptions = useMemo(() => {
    return [
      {
        id: 'top_offers',
        icon: <Icon.Ratings />,
        title: t('app:sidebar.brands'),
        link: ROUTES.BRANDS,
      },
      isTrendingProductsActive && {
        id: 'products',
        icon: <Icon.PriceLabel />,
        title: t('app:sidebar.products'),
        link: ROUTES.TRENDING_PRODUCTS,
      },
      {
        id: 'converter',
        icon: <Icon.Convert />,
        title: t('app:sidebar.link_converter'),
        link: ROUTES.LINK_CONVERTER,
      },
    ].filter(Boolean) as MenuItemType[]
  }, [isTrendingProductsActive, t])

  const analyticsOptions = useMemo(() => {
    return [
      {
        id: 'earnings',
        icon: <Icon.MoneyRound />,
        title: t('app:sidebar.earnings'),
        link: ROUTES.ANALYTICS_EARNINGS,
      },
      {
        id: 'page_analytics',
        icon: <Icon.Chart />,
        title: t('app:sidebar.page_analytics'),
        link: ROUTES.ANALYTICS_PAGE,
      },
      metapicUserId && {
        id: 'payment_details',
        icon: <Icon.CreditCard />,
        title: t('app:sidebar.payment_details'),
        link: ROUTES.ANALYTICS_PAYMENT_DETAILS,
      },
    ].filter(Boolean) as MenuItemType[]
  }, [t, metapicUserId])

  const sideBarItems: (MenuItemType | false)[] = useMemo(() => {
    return [
      {
        id: 'my_page',
        title: t('app:sidebar.my_page'),
        link: ROUTES.MY_PAGE,
        icon: <Icon.MyPage />,
        activeIcon: <Icon.MyPageActive />,
      },
      {
        id: 'analytics',
        title: t('app:sidebar.analytics'),
        isActive: isAnalyticsActive,
        icon: <Icon.Analytics />,
        activeIcon: <Icon.AnalyticsActive />,
        subMenu: analyticsOptions,
      },
      {
        id: 'brands',
        title: t('app:sidebar.brand_collaborations'),
        isActive: isBrandCollabsActive,
        icon: <Icon.BrandCollaborations />,
        activeIcon: <Icon.BrandCollaborationsActive />,
        subMenu: brandsOptions,
      },
    ]
  }, [
    brandsOptions,
    isBrandCollabsActive,
    isAnalyticsActive,
    analyticsOptions,
    t,
  ])

  const myAccountItem: ItemParentLink = {
    id: 'my_account',
    title: t('app:sidebar.my_account'),
    isActive: isMyAccountActive,
  }

  return {
    items: sideBarItems.filter(Boolean) as MenuItemType[],
    myAccountItem,
    isSidebarHidden,
  }
}
