import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useDraftSection } from '../../../../containers/creator/MyPage/hooks/preview.hooks'
import { editDraftContentSection } from '../../../../store/creator/preview.slice'
import { useAddCreatorPageSectionAtomicMutation } from '@web-apps/feature-creator-page'
import {
  ANALYTICS_CATEGORY,
  ContentSectionFormDataType,
  SectionExtension,
  SectionTypeEnum,
  SectionVariantEnum,
} from '@web-apps/utils-types'
import { ROUTES } from '../../../../routes/RouteEnums'
import { SectionContentForm } from './SectionContentForm.component'
import { useAccountDetails } from '../../../../utils/hooks/useAccountDetails.hooks'
import { ToastShareWrapper } from '../../../../components'
import { creatorUrlBuilders } from '@web-apps/utils-shared'

export const AddSectionContent = () => {
  const { t } = useTranslation(['app', 'creator'])
  const navigate = useNavigate()

  const [addSection, { isSuccess, isError, isLoading }] =
    useAddCreatorPageSectionAtomicMutation()
  const { pageId, slug: creatorSlug } = useAccountDetails()

  const updatePreview = useDraftSection('draft', editDraftContentSection)

  const handleSubmit = useCallback(
    ({ imageData, ...sectionData }: ContentSectionFormDataType) =>
      addSection({ pageId, sectionData, imageData }),
    [pageId, addSection]
  )

  useEffect(() => {
    if (isSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(
        <ToastShareWrapper
          href={creatorUrlBuilders.creatorUrlBuilder({ creatorSlug })}
          message={t('creator:toast.add_success', {
            section: '$t(creator:sections.content)',
          })}
          analyticsEventProps={{
            actionNamePrefix: 'add_text_section',
            category: ANALYTICS_CATEGORY.CONTENT,
          }}
        />
      )
    }
  }, [isSuccess, t, creatorSlug, navigate])

  useEffect(() => {
    if (isError) {
      toast.error(
        t('creator:toast.add_error', {
          section: '$t(creator:sections.content)',
        })
      )
    }
  }, [isError, t])

  return (
    <SectionContentForm
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      updatePreview={updatePreview}
      initialValues={{
        text: '',
        type: SectionTypeEnum.CONTENT,
        styles: {
          variant: SectionVariantEnum.PLAIN,
          extension: SectionExtension.NONE,
        },
        published: true,
      }}
    />
  )
}
