import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAddSectionAtomicMutation } from '@web-apps/feature-creator-page'
import { InspectModeEnum, SectionLinkForm } from '../components/SectionLinkForm'
import { useDraftSection } from '../hooks/preview.hooks'
import { editDraftExternalSection } from '../../../../store/creator/preview.slice'
import { useAccountDetails } from '../../../../utils/hooks/useAccountDetails.hooks'
import { ROUTES } from '../../../../routes/RouteEnums'
import { ToastShareWrapper } from '../../../../components'
import {
  creatorUrlBuilders,
  isImageDownloadApiError,
} from '@web-apps/utils-shared'
import {
  ANALYTICS_CATEGORY,
  ExternalLinkSectionFormType,
  SectionTypeEnum,
  SectionVariantEnum,
} from '@web-apps/utils-types'

export const AddAffiliateLinkSection = () => {
  const { t } = useTranslation(['app', 'creator'], { useSuspense: false })
  const navigate = useNavigate()

  const updatePreview = useDraftSection('draft', editDraftExternalSection)
  const { slug: creatorSlug } = useAccountDetails()

  const [addSection, { isSuccess, isError, error, isLoading }] =
    useAddSectionAtomicMutation()

  const handleSubmit = useCallback(
    ({
      isRedirect,
      path,
      imageData,
      ...sectionData
    }: ExternalLinkSectionFormType) =>
      addSection({
        path: path as string,
        sectionData: {
          ...sectionData,
          image_source: imageData?.hasImageSource ? imageData.href : undefined,
        },
        imageData,
        redirect: isRedirect,
      }),
    [addSection]
  )

  useEffect(() => {
    if (isSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(
        <ToastShareWrapper
          message={t('creator:toast.add_success', {
            section: '$t(creator:sections.link)',
          })}
          href={creatorUrlBuilders.creatorUrlBuilder({ creatorSlug })}
          analyticsEventProps={{
            actionNamePrefix: 'add_link_section',
            category: ANALYTICS_CATEGORY.CONTENT,
          }}
        />
      )
    }
  }, [isSuccess, creatorSlug, t, navigate])

  useEffect(() => {
    if (!isError) return

    if (isImageDownloadApiError(error)) {
      toast.error(t('app:imageUploadError'))
      return
    }

    toast.error(
      t('creator:toast.add_error', {
        section: '$t(creator:sections.link)',
      })
    )
  }, [isError, error, t])

  return (
    <SectionLinkForm
      initialValues={{
        label: '',
        href: '',
        type: SectionTypeEnum.AFFILIATE_LINK,
        styles: {
          variant: SectionVariantEnum.IMAGE,
        },
        published: true,
        isRedirect: false,
      }}
      handleSubmit={handleSubmit}
      updatePreview={updatePreview}
      isLoading={isLoading}
      inspectMode={InspectModeEnum.INSPECT}
    />
  )
}
