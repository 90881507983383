import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ProductRecommendationsSectionType,
  SectionType,
} from '@web-apps/utils-types'
import {
  Box,
  Button,
  ButtonSize,
  Flex,
  IconCatalogPaths,
  LoadingIndicator,
  Typography,
} from '@web-apps/ui-shared'
import {
  getSectionImage,
  useAddSectionToContainerAtomicMutation,
  useGetContainerSectionsForAffiliateListQuery,
} from '@web-apps/feature-creator-page'

import { formatProductFormToApi } from '../../../MyPage/SectionProductRecommendationsForm/SectionProductRecommendationsForm.adapters'
import { ProductFormType } from '../../../MyPage/SectionProductRecommendationsForm/SectionProductionRecommendations.types'

import {
  StyledExistingGridCard,
  StyledOverlayMoreItems,
  StyledProductItemCard,
} from './AddAffiliateToExistingGrid.styles'
import { AddProductActionVariant as ActionVariant } from '../../../MyPage/MyPage.types'
import { useAccountDetails } from '../../../../../utils/hooks/useAccountDetails.hooks'

const NUMBER_OF_ITEMS_TO_SHOW_PER_GRID = 4

type AddAffiliateToExistingGridProps = {
  api: {
    fetchGrids: string
  }
  product: ProductFormType
  isDisabled?: boolean
  onSuccess: (variant: ActionVariant, addedSection: SectionType) => void
  onError: (variant: ActionVariant) => void
  onLoading: (status: boolean) => void
}
export const AddAffiliateToExistingGrid = ({
  api,
  isDisabled,
  product,
  onSuccess,
  onError,
  onLoading,
}: AddAffiliateToExistingGridProps) => {
  const { pageId } = useAccountDetails()
  const { isLoading: isGridListLoading, data: productRecommendationsSections } =
    useGetContainerSectionsForAffiliateListQuery(
      { path: api.fetchGrids },
      { refetchOnMountOrArgChange: true }
    )
  const [
    addToGrid,
    {
      isSuccess: isAddToGridSuccess,
      isError: isAddToGridError,
      isLoading: isAddToGridLoading,
      data: gridAddedResponse,
      error: addToGridError,
    },
  ] = useAddSectionToContainerAtomicMutation()
  const { t } = useTranslation(['brands'])

  useEffect(() => {
    if (isAddToGridSuccess && gridAddedResponse)
      onSuccess('existing-grid', gridAddedResponse)
  }, [isAddToGridSuccess, onSuccess, gridAddedResponse])

  useEffect(() => {
    if (isAddToGridError) {
      if (gridLimitReached(addToGridError)) {
        onError('grid-limit-reached')
      } else {
        onError('existing-grid')
      }
    }
  }, [isAddToGridError, addToGridError, onError])

  useEffect(() => {
    onLoading(isAddToGridLoading)
  }, [isAddToGridLoading, onError, onLoading])

  if (isGridListLoading)
    return (
      <Flex justify="space-around">
        <LoadingIndicator size={42} />
      </Flex>
    )

  return (
    <Flex direction="column" gap={24}>
      {(productRecommendationsSections || []).map((container, i) => {
        const items =
          (container as ProductRecommendationsSectionType).items || []
        const extraItemsCount = items.length - NUMBER_OF_ITEMS_TO_SHOW_PER_GRID
        const id = container._links.self.id

        return (
          <StyledExistingGridCard key={id || i}>
            <Typography variant="h4">
              {container.label ||
                t('brands:product_dialog.existing_grid_placeholder')}
            </Typography>
            <Flex justify="space-between">
              <Box mt={16}>
                <Flex gap={8}>
                  {items.map((item, index) => {
                    return index < NUMBER_OF_ITEMS_TO_SHOW_PER_GRID ? (
                      <StyledProductItemCard
                        key={item._links.self.id}
                        title={item.label}
                        as="div"
                      >
                        <img
                          src={
                            getSectionImage(item as SectionType)?.href ||
                            IconCatalogPaths.ZezamLogo
                          }
                          alt={item.label}
                        />
                        {index === NUMBER_OF_ITEMS_TO_SHOW_PER_GRID - 1 &&
                        extraItemsCount > 0 ? (
                          <StyledOverlayMoreItems>
                            +{extraItemsCount}
                          </StyledOverlayMoreItems>
                        ) : null}
                      </StyledProductItemCard>
                    ) : null
                  })}
                </Flex>
              </Box>

              <Button
                inverse
                size={ButtonSize.SLIM}
                isLoading={isDisabled || isAddToGridLoading}
                clickHandler={() => {
                  const { imageData, ...sectionData } =
                    formatProductFormToApi(product)

                  addToGrid({
                    pageId,
                    sectionData,
                    imageData,
                    containerId: id,
                    path: api.fetchGrids,
                  })
                }}
              >
                {t('brands:product_dialog.add_product_to_existing_grid_button')}
              </Button>
            </Flex>
          </StyledExistingGridCard>
        )
      })}
    </Flex>
  )
}

function gridLimitReached(error: any) {
  if (
    error.status !== 400 ||
    error?.data?.type !==
      'https://zezam.gitlab.io/api/problem/constraint-violation'
  ) {
    return false
  }
  return error.data.violations?.some(
    (v: any) =>
      v.field === 'addSection.id' &&
      v.message ===
        '{io.zezam.creator_page.CanAddNestedSection.message}'
  )
}
