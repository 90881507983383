import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { submitLogoutFlow, useCreateLogoutFlow } from '@web-apps/feature-auth'
import { ROUTES } from '../../../routes/RouteEnums'

export const LogoutPage = () => {
  const { token, isLoading, error } = useCreateLogoutFlow()

  useEffect(() => {
    localStorage.removeItem('isSeamlessAuth')
    const logOut = async () => {
      await submitLogoutFlow({
        token,
      })

      window.location.href = ROUTES.LOGIN
    }

    if (error) {
      window.location.href = error.status === 401 ? ROUTES.LOGIN : ROUTES.LOGOUT
    }
    if (token) {
      logOut()
    }
  }, [token, error])

  if (!isLoading && !token) {
    return <Navigate replace to={ROUTES.LOGIN} />
  }

  return null
}
