import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  ANALYTICS_CATEGORY,
  SectionExtension,
  SectionVariantEnum,
} from '@web-apps/utils-types'
import { useAddContainerAtomicMutation } from '@web-apps/feature-creator-page'
import {
  creatorUrlBuilders,
  isImageDownloadApiError,
} from '@web-apps/utils-shared'
import { SectionProductRecommendationsForm } from './SectionProductRecommendationsForm.component'
import { useDraftSection } from '../hooks/preview.hooks'
import { editDraftProductRecommendationsSection } from '../../../../store/creator/preview.slice'
import { ROUTES } from '../../../../routes/RouteEnums'
import {
  formatProductRecommendationsFormToPreview,
  formatProductRecommendationsToCreationApi,
} from './SectionProductRecommendationsForm.adapters'
import { useAccountDetails } from '../../../../utils/hooks/useAccountDetails.hooks'
import { ToastShareWrapper } from '../../../../components'

export const AddSectionProductRecommendations = () => {
  const { t } = useTranslation(['app', 'creator'])
  const navigate = useNavigate()
  const { pageId, slug: creatorSlug } = useAccountDetails()
  const [addContainer, { isSuccess, isError, error, isLoading }] =
    useAddContainerAtomicMutation()
  const updatePreview = useDraftSection(
    'draft',
    editDraftProductRecommendationsSection
  )

  useEffect(() => {
    if (isSuccess) {
      navigate(ROUTES.MY_PAGE)
      toast.success(
        <ToastShareWrapper
          message={t('creator:toast.add_success', {
            section: '$t(creator:sections.product_recommendations)',
            count: Number.POSITIVE_INFINITY,
          })}
          href={creatorUrlBuilders.creatorUrlBuilder({ creatorSlug })}
          analyticsEventProps={{
            actionNamePrefix: 'add_product_recommendations_section',
            category: ANALYTICS_CATEGORY.CONTENT,
          }}
        />
      )
    }
  }, [isSuccess, creatorSlug, t, navigate])

  useEffect(() => {
    if (!isError) return

    if (isImageDownloadApiError(error)) {
      toast.error(t('app:imageUploadError'))
      return
    }

    toast.error(
      t('creator:toast.add_error', {
        section: '$t(creator:sections.product_recommendations)',
        count: Number.POSITIVE_INFINITY,
      })
    )
  }, [isError, error, t])

  return (
    <SectionProductRecommendationsForm
      pageId={pageId}
      initialValues={{
        title: t('creator:product_recommendations.default_grid_title'),
        published: true,
        styles: {
          variant: SectionVariantEnum.PLAIN,
          extension: SectionExtension.NONE,
          is_product_recommendations: true,
        },
        products: [],
      }}
      onChange={(formData) => {
        updatePreview({
          data: formatProductRecommendationsFormToPreview(formData),
        })
      }}
      onSubmit={({ imageData, ...data }) => {
        if (!pageId) return

        addContainer({
          path: `/creator-pages/${pageId}/sections`,
          imageData,
          sectionData: formatProductRecommendationsToCreationApi(data),
        })
      }}
      disabled={!pageId}
      isSubmitting={isLoading}
    />
  )
}
