import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import {
  Button,
  Form,
  FormFieldController,
  FormProps,
  FormValidationDescriptorsType,
  Icon,
  ImageUploader,
  MAX_TITLE_LENGTH,
  ShowHideAnimationWrapper,
  TextField,
} from '@web-apps/ui-shared'
import { FormValidations, truncateText } from '@web-apps/utils-shared'
import {
  AffiliateLinkConversionData,
  Brand,
  CommissionAggregateType,
  ImageNamesEnum,
} from '@web-apps/utils-types'
import { FormFieldBox } from '../../../../components'

import { useFetchExternalLinkImageMutation } from '../../../../services/api'
import {
  MAX_SECTION_IMAGE_SIZE_IN_BYTES,
  MAX_SECTION_IMAGE_SIZE_IN_MEGABYTES,
} from '../utils/MyPage.utils'
import { useURLInputHandlers } from '../hooks/useURLInputHandlers.hook'

import { ProductFormType } from './SectionProductionRecommendations.types'
import { AffiliateTooltip } from '../components/AffiliateTooltip'
import { useInspectLink } from '../hooks/useInspectLink.hook'
import { useFlag } from '@unleash/proxy-client-react'
import { FLAG_COMMISSION_SOURCE_SPLITTING } from '../../../../utils/constants/flags.constants'

type ProductFormProps = {
  initialValues: ProductFormType
  handleSubmit: (
    values: ProductFormType,
    inspectResponse?: AffiliateLinkConversionData
  ) => void
  isSubmitting?: boolean
  pageId?: string
  validationDescriptorsOverrides?: FormValidationDescriptorsType
  renderActionsFooter?: (info: {
    isLoading: boolean
    isLinkConverted: boolean
  }) => JSX.Element
  productBrand?: Brand
  affiliateConverterPath?: string
  isURLReadOnly?: boolean
  affiliateLinkId?: string
  commission?: CommissionAggregateType
  onInspect?: (inspectResponse: AffiliateLinkConversionData) => void
  onlyAffiliateAllowed?: boolean
}

export const ProductForm = ({
  initialValues,
  handleSubmit,
  isSubmitting = false,
  validationDescriptorsOverrides = {},
  renderActionsFooter,
  productBrand,
  affiliateConverterPath,
  isURLReadOnly = false,
  affiliateLinkId,
  commission,
  onInspect,
  onlyAffiliateAllowed,
}: ProductFormProps) => {
  const { t } = useTranslation(['app', 'creator'], { useSuspense: false })
  const [fetchImage, { isLoading: isImageFetching }] =
    useFetchExternalLinkImageMutation()
  const fetchImageRequestRef = useRef<ReturnType<typeof fetchImage>>()
  const isCommissionSourceSplittingEnabled = useFlag(
    FLAG_COMMISSION_SOURCE_SPLITTING
  )
  const [isImageInvalid, setImageInvalid] = useState(false)
  const [
    inspect,
    { status, data: inspectResponse, isLoading: isInspectionLoading, reset },
  ] = useInspectLink({
    defaultStatus: commission ? 'info' : 'initial',
    ignoreMetapicLink: true,
    alternativePath: affiliateConverterPath,
  })
  const debounceFetchImage = useDebouncedCallback(
    (callback: () => void) => callback(),
    750
  )

  const { onPaste, onChangeBuilder, onBlurBuilder, onKeyPressBuilder } =
    useURLInputHandlers({
      onValueChange: async (uri) => {
        if (FormValidations.isValidWebURL(uri)) {
          inspect(uri)
        } else {
          reset()
        }
      },
    })

  const validationSchema: FormProps<ProductFormType>['validations'] = {
    descriptors: {
      href: {
        required: t('app:field_is_required'),
        validate: (value) =>
          FormValidations.isValidURL(value)
            ? true
            : t('app:invalid_url_address'),
      },
      image: {
        validate: (value) => {
          return !value ? t('app:field_is_required') : true
        },
      },
      ...validationDescriptorsOverrides,
    },
  }

  const onSubmit = (data: ProductFormType) => {
    handleSubmit(
      {
        ...data,
        ...(affiliateLinkId && { affiliateLinkId }),
      },
      inspectResponse
    )
  }

  useEffect(() => {
    if (inspectResponse && onInspect) onInspect(inspectResponse)
  }, [inspectResponse, onInspect])

  const showIsLoadingForSubmitButton =
    isSubmitting || isInspectionLoading || isImageFetching

  const showError =
    onlyAffiliateAllowed && inspectResponse && !inspectResponse.isConvertible

  return (
    <Form
      initialWatchableFields={['href', 'image', 'commissionMatchName']}
      initialValues={initialValues}
      onSubmit={onSubmit}
      stopPropagation
      validations={validationSchema}
    >
      {(formProps) => {
        const {
          control,
          getValues,
          setValue,
          formState: { errors },
        } = formProps
        const setURLValue = (value: string) => setValue('href', value)

        return (
          <>
            <FormFieldBox>
              <TextField
                name="href"
                leftDecorator={<Icon.Link />}
                label={t(
                  'creator:product_recommendations.field_label_product_url'
                )}
                showErrorState={showError}
                readOnly={isURLReadOnly}
                onPaste={onPaste}
                onChange={(e) => {
                  onChangeBuilder(setURLValue)(e)

                  const externalUrl = e.target.value
                  const image = getValues('image')

                  debounceFetchImage(() => {
                    if (fetchImageRequestRef.current) {
                      fetchImageRequestRef.current.abort()
                    }

                    if (
                      (!image || !image.wasUserUpload) &&
                      FormValidations.isValidWebURL(externalUrl)
                    ) {
                      const imageRequest = fetchImage({
                        externalUrl,
                      })
                      fetchImageRequestRef.current = imageRequest
                      imageRequest
                        .then((imageResponse) => {
                          if (
                            'data' in imageResponse &&
                            imageResponse.data.imageUrl
                          ) {
                            setValue(
                              'image',
                              {
                                href: imageResponse.data.imageUrl,
                                name: ImageNamesEnum.DEFAULT,
                                hasImageSource: true,
                                wasUserUpload: false,
                              },
                              { shouldValidate: true }
                            )

                            setImageInvalid(false)
                          }

                          if (
                            'data' in imageResponse &&
                            imageResponse.data.pageTitle
                          ) {
                            setValue(
                              'title',
                              truncateText(imageResponse.data.pageTitle, {
                                maxLength: MAX_TITLE_LENGTH,
                                useEllipsis: true,
                              }),
                              { shouldValidate: true }
                            )
                          }
                        })
                        .finally(() => {
                          fetchImageRequestRef.current = undefined
                        })
                    }
                  })
                }}
                onBlur={onBlurBuilder(setURLValue)}
                onKeyPress={onKeyPressBuilder(getValues('href'), setURLValue)}
              />
              {isCommissionSourceSplittingEnabled && !isURLReadOnly && (
                <ShowHideAnimationWrapper state={status}>
                  <AffiliateTooltip
                    showButton={!productBrand}
                    formReturnProps={formProps}
                    status={status}
                    commission={inspectResponse?.commission}
                    missedHints={inspectResponse?.missedHints}
                    matches={inspectResponse?.matches}
                  />
                </ShowHideAnimationWrapper>
              )}
            </FormFieldBox>

            <FormFieldBox>
              <FormFieldController
                name="image"
                control={control}
                render={({ field: { onChange, value: uploadedImage } }) => {
                  return (
                    <ImageUploader
                      inputName="image"
                      isLoading={isImageFetching}
                      label={t(
                        'creator:product_recommendations.field_label_product_image'
                      )}
                      uploadControlText={t('app:image_upload_control_text')}
                      infoMessage={t('app:image_upload_info_generic_message', {
                        megaBytes: MAX_SECTION_IMAGE_SIZE_IN_MEGABYTES,
                      })}
                      image={uploadedImage}
                      onClear={() => onChange(undefined)}
                      onError={() => onChange(undefined)}
                      onImageChange={(imageData) => {
                        onChange({
                          ...imageData,
                          name: uploadedImage?.name || ImageNamesEnum.DEFAULT,
                          wasUserUpload: true,
                        })
                        setImageInvalid(false)
                      }}
                      maxSizeInBytes={MAX_SECTION_IMAGE_SIZE_IN_BYTES}
                      onFileRejected={() => {
                        setImageInvalid(true)
                      }}
                      acceptedExtensions={[
                        '.png',
                        '.jpg',
                        '.jpeg',
                        '.svg',
                        '.gif',
                      ]}
                      isInvalid={Boolean(
                        !getValues('image') && (isImageInvalid || errors.image)
                      )}
                    />
                  )
                }}
              />
            </FormFieldBox>

            <FormFieldBox>
              <TextField
                name="title"
                label={t('creator:product_recommendations.field_label_title')}
                labelDecorator={
                  !validationSchema.descriptors.title?.required
                    ? t('app:optional_form_field_label_decorator')
                    : undefined
                }
                showAllowedChars
                maxLength={MAX_TITLE_LENGTH}
              />
            </FormFieldBox>

            {renderActionsFooter ? (
              renderActionsFooter({
                isLinkConverted: status === 'convertible',
                isLoading: showIsLoadingForSubmitButton,
              })
            ) : (
              <Button width="100%" isLoading={showIsLoadingForSubmitButton}>
                {t('creator:product_recommendations.button_text_save_item')}
              </Button>
            )}
          </>
        )
      }}
    </Form>
  )
}
