import { useEffect, useRef, useCallback } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  Box,
  Button,
  ButtonVariant,
  Card,
  Flex,
  Form,
  Icon,
  ShowHideAnimationWrapper,
  TextField,
  Theme,
  Typography,
} from '@web-apps/ui-shared'
import { FormValidations } from '@web-apps/utils-shared'
import { AffiliateLinkType, ANALYTICS_CATEGORY } from '@web-apps/utils-types'

import { useCreateAffiliateLinkMutation } from '../../../../../../services/affiliates.api'
import { useURLInputHandlers } from '../../../../MyPage/hooks/useURLInputHandlers.hook'

import { StyledIconWrapper } from './Converter.styles'
import { ToastShareWrapper } from '../../../../../../components'
import { AffiliateTooltip } from '../../../../MyPage/components/AffiliateTooltip'
import { useInspectLink } from '../../../../MyPage/hooks/useInspectLink.hook'

type ConverterProps = {
  onAffiliateLinkConverted: (affiliateLink: AffiliateLinkType) => void
}
export const Converter = ({ onAffiliateLinkConverted }: ConverterProps) => {
  const { t } = useTranslation(['app'], { useSuspense: false })
  const resetRef = useRef<() => void | undefined>()
  const [
    inspect,
    { status, data, isError, isLoading, reset: resetInspectResponse },
  ] = useInspectLink({ defaultStatus: 'initial' })

  const { onChangeBuilder, onBlurBuilder, onKeyPressBuilder, onPaste } =
    useURLInputHandlers({
      onValueChange: async (uri) => {
        if (FormValidations.isValidWebURL(uri)) {
          inspect(uri)
        } else {
          reset()
        }
      },
    })

  const [
    createAffiliateLink,
    {
      reset,
      isLoading: isCreateAffiliateLinkLoading,
      isError: isCreateAffiliateLinkError,
      data: createAffiliateLinkData,
    },
  ] = useCreateAffiliateLinkMutation()

  const isAPILoading = isLoading || isCreateAffiliateLinkLoading

  const resetForm = useCallback(() => {
    if (resetRef.current) resetRef.current()
    reset()
    resetInspectResponse()
  }, [reset, resetInspectResponse])

  useEffect(() => {
    if (status === 'convertible') {
      ReactGA.event({
        action: 'affiliates_converter:conversion:success',
        category: ANALYTICS_CATEGORY.AFFILIATES,
      })
    }

    if (status === 'not_convertible') {
      ReactGA.event({
        action: 'affiliates_converter:conversion:failure',
        category: ANALYTICS_CATEGORY.AFFILIATES,
      })
    }
  }, [status])

  useEffect(() => {
    if (createAffiliateLinkData) {
      toast.success(
        <ToastShareWrapper
          message={t('creator:toast.add_success', {
            section: '$t(creator:sections.link)',
          })}
          href={createAffiliateLinkData.affiliateUrl}
          analyticsEventProps={{
            actionNamePrefix: 'add_link_section',
            category: ANALYTICS_CATEGORY.CONTENT,
          }}
        />
      )
      resetForm()

      onAffiliateLinkConverted({ ...createAffiliateLinkData, isFetching: true })
    }
  }, [createAffiliateLinkData, resetForm, t, onAffiliateLinkConverted])

  const handleSubmit = async ({
    href,
    commissionMatchName,
  }: {
    href: string
    commissionMatchName: string
  }) => {
    if (status !== 'convertible') return

    ReactGA.event({
      action: 'affiliates_converter:form:submit',
      category: ANALYTICS_CATEGORY.AFFILIATES,
    })

    const path = data?.matches?.find(
      (item) => item.name === commissionMatchName
    )?.createApiEndpoint

    if (!path) {
      toast.error('app:generic_error')
    } else {
      createAffiliateLink({
        uri: data?.originalLink || href,
        path,
        stopInvalidatesTags: true,
      })
    }
  }

  return (
    <Card>
      <Flex gap={16} align="center">
        <StyledIconWrapper>
          <Icon.Convert />
        </StyledIconWrapper>
        <div>
          <Typography variant="h3">
            {t('app:header.brands.converter_page.form.title')}
          </Typography>
          <Typography variant="hint" color="inactive">
            {t('app:header.brands.converter_page.form.description')}
          </Typography>
        </div>
      </Flex>
      <Form
        initialWatchableFields={['href', 'commissionMatchName']}
        onSubmit={handleSubmit}
        validations={{
          descriptors: {
            href: {
              required: t('app:field_is_required'),
              validate: (value) =>
                FormValidations.isValidURL(value)
                  ? true
                  : t('app:invalid_url_address'),
            },
          },
        }}
      >
        {(formReturnProps) => {
          const { setValue, getValues, setFocus } = formReturnProps
          const setURLValue = (value: string) => setValue('href', value)
          const isSubmitDisabled = status !== 'convertible'
          if (!resetRef.current) {
            resetRef.current = () => {
              setFocus('href')
              setURLValue('')
            }
          }

          return (
            <Box mt={24}>
              <Box mb={24}>
                <TextField
                  placeholder={t(
                    'app:header.brands.converter_page.form.input_placeholder'
                  )}
                  name="href"
                  onPaste={onPaste}
                  onChange={onChangeBuilder(setURLValue)}
                  onBlur={onBlurBuilder(setURLValue)}
                  onKeyPress={onKeyPressBuilder(getValues('href'), setURLValue)}
                  onFocus={() =>
                    ReactGA.event({
                      action: 'affiliates_converter:input:focus',
                      category: ANALYTICS_CATEGORY.AFFILIATES,
                    })
                  }
                  showErrorState={
                    status === 'not_convertible' ||
                    isError ||
                    isCreateAffiliateLinkError
                  }
                  leftDecorator={<Icon.Link />}
                  showErrorIcon={false}
                  rightDecorator={
                    status !== 'initial' ? (
                      <Button
                        variant={ButtonVariant.PLAIN}
                        clickHandler={() => resetForm()}
                      >
                        <Icon.Close fillColor={Theme.Colors.background.dark} />
                      </Button>
                    ) : undefined
                  }
                />
                <ShowHideAnimationWrapper state={status}>
                  <AffiliateTooltip
                    formReturnProps={formReturnProps}
                    status={status}
                    commission={data?.commission}
                    missedHints={data?.missedHints}
                    matches={data?.matches}
                  />
                </ShowHideAnimationWrapper>
              </Box>
              <Button
                type="submit"
                width="100%"
                isLoading={isAPILoading}
                disabled={isSubmitDisabled}
              >
                <Flex justify="center" gap={8}>
                  <Icon.Convert
                    fillColor={
                      isSubmitDisabled
                        ? Theme.Colors.typography.inactive
                        : Theme.Colors.typography.inverse
                    }
                  />
                  {t('app:header.brands.converter_page.form.submit_button')}
                </Flex>
              </Button>
            </Box>
          )
        }}
      </Form>
    </Card>
  )
}
