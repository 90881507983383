import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import {
  Theme,
  media,
  BREAKPOINTS,
  Card,
  IconCatalogPaths,
} from '@web-apps/ui-shared'

const StyledMenuItemCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: hidden;
  padding-top: 14px;
  position: relative;
  cursor: pointer;

  ${media.from(
    BREAKPOINTS.lg,
    `
      flex: unset;
      width: 40px;
      height: 40px;
      margin-top: 6px;
      margin-bottom: 6px;
      padding-top: 0;
      overflow: visible;
      justify-content: center;
      border-radius: 8px;
      transition: background-color 0.3s ease;
    `
  )}
`

export const StyledTooltip = styled.div`
  display: none;

  ${media.from(
    BREAKPOINTS.lg,
    `
      display: block;
      position: absolute;
      top: 7px;
      left: 58px;
      opacity: 0;
      z-index: -1;
      padding: 4px 8px;
      border-radius: 4px;
      background: ${Theme.Button.backgroundColor};
      white-space: nowrap;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - 8px);
        left: -3px;
        z-index: -1;
        width: 16px;
        height: 16px;
        transform: rotate(45deg);
        background: ${Theme.Button.backgroundColor};
      }
    `
  )};
`

export const StyledMenuActiveBackground = styled.div<{ $isActive?: boolean }>`
  display: none;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  background: ${({ $isActive }) =>
    $isActive ? Theme.Colors.background.alternate : Theme.Colors.lightSand};

  ${media.from(
    BREAKPOINTS.lg,
    `
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      transition: opacity 0.3s ease;
    `
  )};
`

export const StyledMenuItemLink = styled(NavLink)`
  ${StyledMenuItemCSS}

  ${media.from(
    BREAKPOINTS.lg,
    `
      &:hover ${StyledTooltip} {
        opacity: 1;
        z-index: 0;
      }

       &:hover ${StyledMenuActiveBackground} {
        opacity: 1;
      }
    `
  )}
`

export const StyledMenuItem = styled.div`
  ${StyledMenuItemCSS}

  ${media.from(
    BREAKPOINTS.lg,
    `
       &:hover ${StyledMenuActiveBackground} {
        opacity: 1;
      }
    `
  )}
`
export const StyledIcon = styled.div`
  position: relative;
  z-index: 1;
`

export const StyledLabel = styled.span`
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${media.from(
    BREAKPOINTS.lg,
    css`
      display: none;
    `
  )};
`

export const StyledBadge = styled.span`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  min-width: 16px;
  padding: 2px;
  border-radius: 4px;
  background: ${Theme.Colors.background.badge};
  color: ${Theme.Colors.typography.inverse};
  margin-left: auto;
`

export const StyledBadgeWrapper = styled.span`
  position: absolute;
  right: 18px;
  top: 8px;
  z-index: 2;

  ${media.from(
    BREAKPOINTS.lg,
    css`
      right: -1px;
      top: -2px;
    `
  )};
`

export const StyledSubMenuWrapper = styled(Card)<{
  $top?: number
  $bottom?: number
}>`
  display: none;
  position: absolute;
  left: 70px;
  top: ${({ $top }) => ($top ? `${$top}px` : 'auto')};
  bottom: ${({ $bottom }) => ($bottom ? `${$bottom}px` : 'auto')};
  z-index: 3;
  width: 228px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
`

export const StyledMenuItemCoverMobile = styled.span`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;

  ${media.from(
    BREAKPOINTS.lg,
    `
      display: none;
    `
  )};
`
export const StyledMenuItemCoverDesktop = styled.span`
  display: none;

  ${media.from(
    BREAKPOINTS.lg,
    `
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -24px;
      right: -24px;
      cursor: pointer;
      z-index: 3;

      &:hover ${StyledSubMenuWrapper} {
        display: block;
      }
    `
  )};
`

export const StyledMenuItemAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  padding-top: 12px;
  overflow: hidden;
  cursor: pointer;

  ${media.from(
    BREAKPOINTS.lg,
    css`
      padding-top: 0;
      flex: unset;
      margin-top: auto;
      overflow: visible;
    `
  )};
`

export const StyledSubMenuLink = styled.button<{ $isActive?: boolean }>`
  display: block;
  width: 100%;
  padding: 16px 0;
  border: none;
  white-space: nowrap;
  background: none;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${Theme.Colors.line};
  }

  ${media.from(
    BREAKPOINTS.lg,
    `
      padding: 8px;
      margin-top: 8px;
      border-radius: 8px;

      &:first-child {
        padding: 8px;
      }
      &:not(:last-child) {
        border: none;
      }

      &:hover {
        background: ${Theme.Colors.lightSand};
      }
    `
  )};

  ${({ $isActive }) =>
    $isActive
      ? media.from(
          BREAKPOINTS.lg,
          `
      background: ${Theme.Colors.background.highlight};
      cursor: default;

      &:hover {
        background: ${Theme.Colors.background.highlight};
      }
    `
        )
      : ''}
`

export const StyledMenuItemAccountImg = styled.div<{
  $url: string | undefined
  $isActive?: boolean
}>`
  position: relative;
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  border-radius: 24px;
  background: ${({ $url }) =>
    `url(${$url || IconCatalogPaths.ImageProfilePlaceholder})`};
  background-size: cover;

  ${({ $isActive }) =>
    $isActive &&
    css`
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        border: 2px solid ${Theme.Button.borderColor};
        border-radius: 22px;
        box-shadow: 0 0 0 1px inset ${Theme.Colors.background.base};
      }
    `}

  ${media.from(
    BREAKPOINTS.lg,
    css`
      flex: 0 0 40px;
      width: 40px;
      height: 40px;
      border-radius: 40px;

      &:before {
        width: 40px;
        height: 40px;
        border-radius: 40px;
      }
    `
  )};
`

export const StyledItemGroupWrapper = styled.div`
  border-bottom: 1px solid ${Theme.Colors.background.alternate};

  & ${StyledSubMenuLink} {
    border-bottom: none;
  }

  ${media.from(
    BREAKPOINTS.lg,
    css`
      margin: 8px 0;
      padding-bottom: 8px;
    `
  )};
`
