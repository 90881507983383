const getValue = (name: string): string | undefined =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((window as any)?.env || {})[name]

export const config = {
  environment: getValue('ENVIRONMENT'),
  apiBaseUrl:
    process.env.NODE_ENV === 'test' ? '/' : getValue('BACKEND_BASE_URL'),
  authUrl: getValue('AUTH_URL') || '',
  unleash: {
    proxyUrl: getValue('UNLEASH_PROXY_URL') || '',
    clientKey: getValue('UNLEASH_CLIENT_KEY') || '',
    environment: getValue('UNLEASH_ENVIRONMENT_NAME'),
  },
  sentry: {
    dsn: getValue('SENTRY_DSN'),
    errorsSampleRate: Number.parseFloat(
      getValue('SENTRY_ERRORS_SAMPLE_RATE') || '1.0'
    ),
    tracesSampleRate: Number.parseFloat(
      getValue('SENTRY_TRACES_SAMPLE_RATE') || '0.05'
    ),
    environment: getValue('SENTRY_ENVIRONMENT'),
  },
  iframelyKey: getValue('IFRAMELY_KEY'),
  chargebeeSite: getValue('CHARGEBEE_SITE'),
  googleAnalyticsId: getValue('GOOGLE_ANALYTICS_ID'),
  facebookAppId: getValue('FACEBOOK_APP_ID'),
  simpleLocaliseProjectToken: getValue(
    'SIMPLELOCALIZE_CREATOR_APP_PROJECT_TOKEN'
  ),
}
